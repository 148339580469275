import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
router.beforeEach(async(to, from, next) => {
    window.sessionStorage.setItem("index", JSON.stringify(to.meta.index))
    next()
})

router.afterEach(() => {
    // finish progress bar
    NProgress.done()
})