<template>
  <div>
    <div class="home" v-if="isMobile">
      <div style="margin-bottom: 50px; width: 100%; height: 500px">
        <img
          style="width: 100%; height: 500px"
          :src="aboutOurHeadChart"
          alt=""
        />
      </div>
      <div class="aboutUs">
        <div class="aboutUs-title">
          <div class="underline"></div>
          <div class="aboutUs-title-name">公司概况</div>
        </div>
        <div class="aboutUs-content" style="white-space:pre-wrap"  v-html="aboutUsContent.replace(/\n|\r\n/g, '<br>').replace(/ /g, '  ')" ></div>
          <!-- <span style="display:inline-block;text-indent: 2em"
            >保亿家网络科技有限公司是一家专为保险行业提供综合服务的集成服务商。目前，保亿家已开展的业务有保险主体微信公众号活动策划与运用、车展项目、私域流量运营、新媒体宣传服务、产品宣传服务、社区宣传服务、客户引流及咨询服务、客户营销活动专项策划、线上宣传推广、客户线上安全教育平台、客户权益兑换、保险理赔公估聚合、保险业务流程管理辅助系统等。同时申请获批的软著有众估宝、保险网上商城、易估宝等14余项。顺利通过GB/T22080-2016/ISO/IEC27001:2013认证评审，取得该信息安全管理体系认证资质。</span
          ><br/>
          <span style="display:inline-block;text-indent: 2em">
            保亿家作为互联网科技企业，自成立以来高度重视客户的隐私保护和企业的信息安全建设。经过公司不断的努力，于2022年05月31日获得了由公安部颁发的第三级国家信息安全系统安全等级保护资质，是目前民用企业办理的最高等级。同时，基于客户信息安全保障需求，与中国人保开展了多轮的专项研讨，于2023年2月8日，双方达成了10亿元保单额度的信息技术应用服务保单合作，保障范围包含服务器中断责任、数据丢失责任、信息泄露丢失保险保障等，此保单不仅是保险行业首单，也是全国金融行业综合集采服务供应商行业首单。
          </span><br/>
          <span style="display:inline-block;text-indent: 2em">
            目前，保亿家已分设湖北分公司、内蒙古分公司、陕西分公司、绍兴分公司、杭州分公司、上海分公司、安徽分公司、河北分公司。
          </span>3 -->
          <!-- {{ aboutUsContent }} -->
        
        <!-- <div class="aboutUs-content"></div> -->
      </div>
      <!-- <div class="coreIdea">
      <div class="coreIdea-title">
        <div class="underline"></div>
        <div class="coreIdea-title-name">核心理念</div>
      </div>
      <div class="coreIdea-content">
        <div class="coreIdea-content-left">
          <div class="coreIdea-content-mission">
            <span class="title">使命</span>
            <span class="content">简单、快速、突破、共赢</span>
          </div>
          <div class="coreIdea-content-vision">
            <span class="title">愿景</span>
            <span class="content"
              >追求成为一家活102年的好公司。我们的愿
              景是让客户相会、工作和生活在保亿家。</span
            >
          </div>
        </div>
        <div class="coreIdea-content-right">
          <span class="title">价值观</span>
          <span class="content"
            >客户第一，员工第二，股东第三
            <br />因为信任，所以简单唯一不变的是变化<br />
            今天最好的表现是明天最低的要求<br />
            此时此刻，非我莫属<br />
            认真生活，快乐工作</span
          >
        </div>
      </div>
    </div> -->

      <!-- <div class="companyHistory">
      <div class="companyHistory-title">
        <div class="underline"></div>
        <div class="companyHistory-title-name">公司发展</div>
      </div>
      <div class="companyHistory-content">
        <div class="companyHistory-content-year">
          <span :class="{
                'companyHistory-content-year-span': true,
                active: activeIndex2 == index,
              }" v-for="(item, index) in yearList" @click="switchingYear(item,index)" :key="index">{{
            item.yearM
          }}</span>
        </div>
        <div class="companyHistory-content-month">
          <div class="companyHistory-content-month-l">
            <img :src="companyDevelopmentBackground" alt="" />
            <div style="color: #ffffff" class="companyHistory-introduce" v-html="historyDetails"></div>
          </div>
          <div class="companyHistory-content-month-r">
            <div
              :class="{
                'companyHistory-content-img-concrete': true,
                active: activeIndex == index,
              }"
              v-for="(item, index) of monthList"
              :key="index"
              @click="selectOnlineItems(item, index)"
            >
              {{ item.yearM }}
            </div>
          </div>
        </div>
      </div>
    </div> -->

      <div class="officeEnvironment">
        <div class="officeEnvironment-title">
          <div class="underline"></div>
          <div class="officeEnvironment-title-name">办公环境</div>
        </div>
        <div class="officeEnvironment-content">
          <el-carousel
            style="width: 100%; height: 100%"
            :interval="4000"
            type="card"
            height="450px"
          >
            <el-carousel-item v-for="(item, index) in carouseData" :key="index">
              <img :src="item.url" style="width: 100%; height: 100%" alt="" />
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>
    </div>

    <div class="" v-else>
      <div style="width: 100vw; height: 53.3333vw">
        <img
          style="width: 100%; height: 100%"
          :src="aboutOurHeadChart"
          alt=""
        />
      </div>
      <div class="mCompanyProfile">
        <div class="mCompanyProfile-title">公司概况</div>
        <span
          style="
            display: inline-block;
            line-height: 5.8667vw;
            font-size: 3.7333vw;
            white-space:pre-wrap
          "
           v-html="aboutUsContent.replace(/\n|\r\n/g, '<br>').replace(/ /g, '  ')" 
          ></span
        >
      </div>
      <div class="mOfficeEnvironment">
        <div class="mOfficeEnvironment-title">办公环境</div>
        <div class="mswiper">
          <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
            <van-swipe-item v-for="item in carouseData" :key="item">
              <img :src="item.url" alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import {
  getAboutUs,
  getHeadView,
  getOfficeEnvironment,
  getSubsidiaryCompany,
  getYear,
  getYearToMonth,
  getHistoryDetails,
} from "../http/api/api";
import serverConfig from "../http/config/index";
export default {
  name: "aboutUs",
  data() {
    return {
      aboutUsContent: "",
      aboutOurHeadChart: null,
      carouseData: [],
      activeIndex: 0,
      activeIndex2: 0,
      latestYear: "",
      companyDevelopmentBackground: "",
      yearList: [],
      monthList: [],
      electionMonth: "",
      historyDetails: "",
      isMobile: false,
    };
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
    this.getAboutUs();
    this.getHeadView();
    this.getOfficeEnvironment();
    this.getYear();
    this.getCompanyDevelopmentBackground();
    setTimeout(() => {
      this.getHistoryDetails();
    }, 500);
  },

  methods: {
    // to() {
    //   this.$refs["aboutUs"].scrollIntoView(true);
    // },
    // toWheelSeedingPicture() {
    //   this.$refs["wheelSeedingPicture"].scrollIntoView(true);
    // },
    switchingYear(item, index) {
      this.activeIndex2 = index;
      this.activeIndex = 0;
      this.latestYear = item.yearM;
      this.getYearToMonth(item.yearM);
      setTimeout(() => {
        this.getHistoryDetails();
      }, 300);
      console.log(item);
    },
    getYear() {
      getYear().then((res) => {
        this.latestYear = res.data[0].yearM;
        this.yearList = res.data;
        this.getYearToMonth(this.latestYear);
        console.log(res);
      });
    },
    getYearToMonth(year) {
      getYearToMonth(year).then((res) => {
        this.monthList = res.data;
        if (res.data.length >= 0) {
          this.electionMonth = res.data[0].yearM;
        }
      });
    },
    getHistoryDetails() {
      let yearM = this.latestYear + "-" + this.electionMonth;
      getHistoryDetails(yearM).then((res) => {
        if (res.data.length >= 0) {
          this.historyDetails = res.data[0].commits;
        }
        console.log(res);
      });
    },
    selectOnlineItems(item, index) {
      this.activeIndex = index;
      this.electionMonth = item.yearM;
      this.getHistoryDetails();
    },
    getAboutUs() {
      getAboutUs().then((res) => {
        console.log(res);
        this.aboutUsContent = res.data[0].content;
      });
    },
    getCompanyDevelopmentBackground() {
      getHeadView(20).then((res) => {
        res.data.forEach((item) => {
          this.companyDevelopmentBackground =
            serverConfig.baseURL + item.imgUrl;
        });
      });
    },
    getHeadView() {
      getHeadView(11).then((res) => {
        res.data.forEach((item) => {
          this.aboutOurHeadChart = serverConfig.baseURL + item.imgUrl;
        });
        console.log(this.aboutOurHeadChart);
      });
    },
    getOfficeEnvironment() {
      getOfficeEnvironment().then((res) => {
        let data = res.data.map((item) => {
          return { url: serverConfig.baseURL + item.imgUrl };
        });
        this.carouseData = data;
      });
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.aboutUs {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 75px;
  .aboutUs-title {
    .underline {
      width: 180px;
      height: 4px;
      background: #dc1010;
      // margin-top: 6px;
      // margin-bottom: 25px;
    }
    .aboutUs-title-name {
      font-size: 36px;
      font-weight: 500;
      color: #dc1010;
      margin-top: 20px;
    }
  }
  .aboutUs-content {
    border-top: 1px solid #999;
    width: 1000px;
    padding-right: 20px;
    padding-top: 10px;
    box-sizing: border-box;
    font-size: 20px;
    font-weight: 400;
    color: #333333;
    line-height: 30px;
    // .underline {
    //   width: 1000px;
    //   height: 1px;
    //   background: #999999;
    // }
  }
}

.coreIdea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  .coreIdea-title {
    .underline {
      width: 180px;
      height: 4px;
      background: #dc1010;
    }
    .coreIdea-title-name {
      font-size: 36px;
      font-weight: 500;
      color: #dc1010;
      margin-top: 20px;
    }
  }
  .coreIdea-content {
    border-top: 1px solid #999;
    width: 1000px;
    padding-right: 20px;
    padding-top: 10px;
    display: flex;
    box-sizing: border-box;
    .coreIdea-content-left {
      width: 370px;
      margin-top: 22px;
      .coreIdea-content-mission {
        .title {
          display: block;
          font-size: 24px;
          font-weight: 500;
          color: #dc1010;
          margin-bottom: 20px;
        }
        .content {
          display: block;
          width: 370px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
          margin-bottom: 50px;
        }
      }
      .coreIdea-content-vision {
        .title {
          display: block;
          font-size: 24px;
          font-weight: 500;
          color: #dc1010;
          margin-bottom: 20px;
        }
        .content {
          display: block;
          width: 370px;
          font-size: 20px;
          font-weight: 400;
          color: #333333;
        }
      }
    }
    .coreIdea-content-right {
      margin-top: 22px;
      margin-left: 40px;
      .title {
        display: block;
        font-size: 24px;
        font-weight: 500;
        color: #dc1010;
        margin-bottom: 20px;
      }
      .content {
        display: block;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
      }
    }
  }
}

.companyHistory {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 50px;
  .companyHistory-title {
    .underline {
      width: 180px;
      height: 4px;
      background: #dc1010;
    }
    .companyHistory-title-name {
      font-size: 36px;
      font-weight: 500;
      color: #dc1010;
      margin-top: 20px;
    }
  }

  .companyHistory-content {
    border-top: 1px solid #999;
    width: 1000px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;

    .companyHistory-content-year {
      margin-top: 20px;
      .companyHistory-content-year-span {
        display: inline-block;
        color: #d8d8d8;
        font-size: 26px;
        margin-right: 20px;
        cursor: pointer;
      }
    }
    .companyHistory-content-month {
      margin-top: 20px;
      width: 100%;
      height: 500px;
      display: flex;
      position: relative;
      .companyHistory-content-month-l {
        width: 100%;
        height: 500px;
        position: relative;
        img {
          width: 100%;
          height: 100%;
        }
        .companyHistory-introduce {
          width: 70%;
          height: 70%;
          position: absolute;
          top: 100px;
          left: 45px;
          font-size: 20px;
          color: #ffffff !important;
          overflow: auto;
          span {
            color: #ffffff !important;
          }
          &::-webkit-scrollbar {
            width: 10px;
            height: 10px;
          }
          &::-webkit-scrollbar-track {
            width: 6px;
            background: rgba(#101f1c, 0.1);
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
          }
          // 滚动条的轨道（里面装有Thumb）
          &::-webkit-scrollbar-thumb {
            background-color: rgba(144, 147, 153, 0.5);
            background-clip: padding-box;
            min-height: 28px;
            -webkit-border-radius: 2em;
            -moz-border-radius: 2em;
            border-radius: 2em;
            transition: background-color 0.3s;
            cursor: pointer;
          }
          &::-webkit-scrollbar-thumb:hover {
            background-color: rgba(144, 147, 153, 0.3);
          }
        }
      }

      .companyHistory-content-month-r {
        width: 20%;
        height: 500px;
        background: #393a3a;
        opacity: 0.7;
        box-sizing: border-box;
        overflow-y: auto;
        position: absolute;
        right: 0;
        &::-webkit-scrollbar {
          width: 10px;
          height: 10px;
        }
        &::-webkit-scrollbar-track {
          width: 6px;
          background: rgba(#101f1c, 0.1);
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
        }
        // 滚动条的轨道（里面装有Thumb）
        &::-webkit-scrollbar-thumb {
          background-color: rgba(144, 147, 153, 0.5);
          background-clip: padding-box;
          min-height: 28px;
          -webkit-border-radius: 2em;
          -moz-border-radius: 2em;
          border-radius: 2em;
          transition: background-color 0.3s;
          cursor: pointer;
        }
        &::-webkit-scrollbar-thumb:hover {
          background-color: rgba(144, 147, 153, 0.3);
        }

        .companyHistory-content-img-concrete {
          height: 100px;
          width: 100%;
          font-size: 26px;
          border-bottom: 1px solid #a3a3a3;
          box-sizing: border-box;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }
    }
  }
}

.active {
  color: #d3231c !important;
}
.officeEnvironment {
  margin-top: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .officeEnvironment-title {
    .underline {
      width: 180px;
      height: 4px;
      background: #dc1010;
    }
    .officeEnvironment-title-name {
      font-size: 36px;
      font-weight: 500;
      color: #dc1010;
      margin-top: 20px;
    }
  }
  .officeEnvironment-content {
    border-top: 1px solid #999;
    width: 1000px;
    padding-right: 20px;
    padding-top: 10px;
    display: flex;
    box-sizing: border-box;
    height: 510px;
    // background-color: red;
    margin-bottom: 70px;
  }
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n + 1) {
  background-color: #d3dce6;
}

.mCompanyProfile {
  width: 85vw;
  margin: 0 auto;
  margin-top: 5.3333vw;
  .mCompanyProfile-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
}
.mOfficeEnvironment {
  margin-top: 5.3333vw;
  .mOfficeEnvironment-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }

  .mswiper {
    width: 85vw;
    margin: 0 auto;
    .my-swipe .van-swipe-item {
      width: 100%;
      height: 53.3333vw;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>