<template>
  <div id="app">
    <div class="appHome">
      <div class="top" v-if="isMobile">
        <div class="logo">
          <img src="./assets/logo.png" alt="" />
        </div>
        <div class="menu">
          <div
            :class="menuIndex == index ? 'menu-box-active' : 'menu-box'"
            v-for="(item, index) in menuData"
            :key="index"
            @click="selMenu(index)"
          >
            <div class="menu-title">{{ item.title }}</div>
            <div class="menu-sel"></div>
          </div>
        </div>
      </div>
      <div v-else>
        <div class="MoveAppHome">
          <div class="mLogo">
            <img src="./assets/logo.png" alt="" />
          </div>
          <div class="menu" @click="switchingMenuDetails"></div>
        </div>
        <div class="menuDetails" v-if="menuDetailsIf">
          <div
            class="menuDetails-C"
            v-for="(item, index) in menuData"
            :key="index"
            @click="selMenu(index)"
          >
            {{ item.title }}
          </div>
        </div>
      </div>
      <router-view />
    </div>

    <div class="footer" v-if="isMobile">
      <div class="footer-content">
        <div class="footer-content-l">
          <div class="footer-content-l-1" @click="selMenu(1)">
            <div style="font-size: 18px; color: #2d2d2d; margin-bottom: 20px">
              关于我们
            </div>
            <span class="footer-content-l-1-font">公司概况</span>
            <!-- <span class="footer-content-l-1-font">核心理念</span> -->
            <span class="footer-content-l-1-font">公司发展</span>
            <span class="footer-content-l-1-font">办公环境</span>
          </div>
          <div class="footer-content-l-1" @click="selMenu(2)">
            <div style="font-size: 18px; color: #2d2d2d; margin-bottom: 20px">
              新闻动态
            </div>
            <span class="footer-content-l-1-font">公司新闻</span>
            <span class="footer-content-l-1-font">行业动态</span>
          </div>
          <div class="footer-content-l-1" @click="selMenu(3)">
            <div style="font-size: 18px; color: #2d2d2d; margin-bottom: 20px">
              项目介绍
            </div>
            <span class="footer-content-l-1-font">核心业务</span>
            <span class="footer-content-l-1-font">案例介绍</span>
          </div>

          <div class="footer-content-l-1" @click="selMenu(4)">
            <div style="font-size: 18px; color: #2d2d2d; margin-bottom: 20px">
              广告中心
            </div>
            <span class="footer-content-l-1-font">广告</span>
          </div>
          <div class="footer-content-l-1" @click="selMenu(5)">
            <div style="font-size: 18px; color: #2d2d2d; margin-bottom: 20px">
              加入我们
            </div>
            <span class="footer-content-l-1-font">职业发展</span>
            <span class="footer-content-l-1-font">薪酬福利</span>
            <span class="footer-content-l-1-font">招聘岗位</span>
            <span class="footer-content-l-1-font">联系方式</span>
          </div>
          <div class="footer-content-l-1" @click="selMenu(6)">
            <div style="font-size: 18px; color: #2d2d2d; margin-bottom: 10px">
              联系我们
            </div>
            <span class="footer-content-l-1-font">公司地址</span>
            <span class="footer-content-l-1-font">互动留言</span>
          </div>
        </div>
        <div class="footer-content-r">
          <!-- <div class="footer-content-r-1" style="margin-right: 20px">
            <img src="../src/assets/ljsc.jpg" alt="" />
            <div style="font-size: 16px; color: #5b5b5b">扫码进入丽璟商城</div>
          </div> -->
          <div class="footer-content-r-1" style="margin-right: 20px">
            <img src="../src/assets/gzh.jpg" alt="" />
            <div style="font-size: 16px; color: #5b5b5b">扫码关注公众号</div>
          </div>
          <div class="footer-content-r-1" style="margin-left: 20px">
            <img src="../src/assets/gzh2.png" alt="" />
            <div style="font-size: 16px; color: #5b5b5b">扫码关注公众号</div>
          </div>
        </div>
      </div>
      <div class="ICP">
        <a
          style="text-decoration: none; color: #939393"
          href="https://beian.miit.gov.cn/ "
          target="_blank"
          >浙ICP备20024745号-1</a
        >
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33030302001163"
          style="
            display: inline-block;
            text-decoration: none;
            height: 20px;
            line-height: 20px;
            margin-left: 10px;
          "
          ><img src="../src/assets/batb.png" style="float: left" />
          <p
            style="
              float: left;
              height: 20px;
              line-height: 20px;
              margin: 0px 0px 0px 5px;
              color: #939393;
            "
          >
            浙公网安备 33030302001163号
          </p></a
        >
      </div>

      <!-- <img class="vcode" src="./assets/vcode.png" alt="" /> -->
    </div>
    <div v-else class="mfooter">
      <div class="mfooter-img">
        <div class="mfooter-img-content">
          <img src="../src/assets/ljsc.jpg" alt="" />
          <div style="font-size: 3.2vw; color: #5b5b5b">扫码进入丽璟商城</div>
        </div>
        <div class="mfooter-img-content">
          <img src="../src/assets/gzh.jpg" alt="" />
          <div style="font-size: 3.2vw; color: #5b5b5b">扫码关注公众号</div>
        </div>
        <div class="mfooter-img-content">
          <img src="../src/assets/gzh2.png" alt="" />
          <div style="font-size: 3.2vw; color: #5b5b5b">扫码关注公众号</div>
        </div>
      </div>
      <div class="mICP">
        <a
          style="text-decoration: none; color: #939393; font-size: 3.2vw"
          href="https://beian.miit.gov.cn/ "
          target="_blank"
          >浙ICP备20024745号-1</a
        >
        <a
          target="_blank"
          href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33030302001163"
          style="
            display: inline-block;
            text-decoration: none;
            height: 5.3333vw;
            line-height: 5.3333vw;
            margin-left: 2.6667vw;
          "
          ><img src="../src/assets/batb.png" style="float: left" />
          <p
            style="
              float: left;
              height: 5.3333vw;
              line-height: 5.3333vw;
              margin: 0px 0px 0px 1.3333vw;
              color: #939393;
              font-size: 3.2vw;
            "
          >
            浙公网安备 33030302001163号
          </p></a
        >
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      menuStyle: "menu-box",
      menuData: [
        {
          title: "首页",
          menuStyle: "menu-box",
        },
        {
          title: "关于我们",
          menuStyle: "menu-box",
        },
        {
          title: "新闻动态",
          menuStyle: "menu-box",
        },
        {
          title: "项目介绍",
          menuStyle: "menu-box",
        },
        {
          title: "广告中心",
          menuStyle: "menu-box",
        },
        {
          title: "公司内网",
          menuStyle: "menu-box",
        },
        {
          title: "加入我们",
          menuStyle: "menu-box",
        },
        {
          title: "联系我们",
          menuStyle: "menu-box",
        },
      ],
      menuIndex: 0,
      isMobile: false,
      menuDetailsIf: false,
    };
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
    this.$router.afterEach((to, from) => {
      if (to.name == "newsTrends") {
        this.menuIndex = 2;
      } else if (to.name == "projectBusiness") {
        this.menuIndex = 3;
      }
    });
    this.menuIndex = JSON.parse(window.sessionStorage.getItem("index"));
  },
  methods: {
    selMenu(idx) {
      this.menuIndex = idx;
      console.log(this.menuIndex);
      switch (idx) {
        case 0:
          this.$router.push("/");
          break;
        case 1:
          this.$router.push("/us");
          break;
        case 2:
          this.$router.push("/newsTrends");
          break;
        case 3:
          this.$router.push("/projectBusiness");
          break;
        case 4:
          this.$router.push("/advertisingCenter");
          break;
        case 5:
          window.open("https://nbzx.baoyj.net/information/home","__blank")
          break;
        case 6:
          this.$router.push("/joinUs");
          break;
        case 7:
          this.$router.push("/contactUs");
          break;
        default:
          break;
      }
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
    switchingMenuDetails() {
      this.menuDetailsIf = !this.menuDetailsIf;
    },
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  height: 100%;
}
.appHome {
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100%;
}
body {
  padding: 0px;
  margin: 0px;
  height: 100%;
}
html {
  height: 100%;
  padding: 0px;
  margin: 0px;
}

.top {
  width: 100%;
  height: 65px;
  border-bottom: 1px solid #d2d2d2;
  display: flex;
  justify-content: space-between;
  align-items: center;
  // position: relative;
  .logo {
    height: 70%;
    margin-right: 20px;
    // position: absolute;
    // left: 15%;
    img {
      height: 100%;
    }
  }
  .menu {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .menu-box {
      color: #333333;
      margin: 0 20px;
      height: 100%;
      padding: 0 15px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      white-space: nowrap;

      .menu-sel {
        transform: scale(0, 1);
        transition: 0.5s;
        width: 100%;
        height: 7px;
        position: absolute;
        bottom: -1px;
        background: #de1e1e;
      }
    }

    .menu-box-active {
      white-space: nowrap;
      margin: 0 20px;
      height: 100%;
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      cursor: pointer;
      .menu-sel {
        width: 100%;
        height: 7px;
        position: absolute;
        bottom: -1px;
        background: #de1e1e;
      }
    }

    .menu-box:hover {
      .menu-sel {
        transform: scale(1, 1);
        width: 100%;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 280px;
  border-top: 1px solid #dddddd;

  .footer-content {
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .footer-content-l {
      width: 65%;
      display: flex;
      justify-content: space-between;
      .footer-content-l-1 {
        display: flex;
        flex-direction: column;
        cursor: pointer;
        .footer-content-l-1-font {
          font-size: 16px;
          color: #5b5b5b;
          line-height: 30px;
        }
      }
    }
    .footer-content-r {
      display: flex;
      .footer-content-r-1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        img {
          width: 150px;
          height: 150px;
        }
      }
    }
  }

  .ICP {
    display: flex;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -42px);
    text-decoration: none;
  }
}

.MoveAppHome {
  width: 100%;
  height: 13.3333vw;
  padding: 1.3333vw;
  padding-bottom: 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.3333vw;
  padding-left: 6.6667vw;
  padding-right: 5.3333vw;
  .mLogo {
    height: 85%;
    width: 30%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .menu {
    width: 8vw;
    height: 0.8vw;
    background-color: rgb(215, 51, 45);
    border-radius: 0.8vw;
    margin-right: 2.6667vw;
    cursor: pointer;
    &.menu::before {
      content: "";
      display: block;
      position: absolute;
      width: 8vw;
      height: 0.8vw;
      background-color: rgb(215, 51, 45);
      border-radius: 0.8vw;
      top: 4vw;
    }
    &.menu::after {
      content: "";
      display: block;
      position: absolute;
      width: 8vw;
      height: 0.8vw;
      background-color: rgb(215, 51, 45);
      border-radius: 0.8vw;
      top: 10.1333vw;
    }
  }
}

.menuDetails {
  width: 35%;
  background-color: #fff;
  position: absolute;
  right: 0;
  z-index: 999;
  .menuDetails-C {
    font-size: 3.7333vw;
    line-height: 10.6667vw;
    color: #000;
    padding-left: 2.6667vw;
    border-top: 1px solid #fff;
  }
}

.mfooter {
  width: 100vw;
  height: 30vw;
  border-top: 1px solid #dddddd;
  margin-top: 2.6667vw;
  padding-top: 1.3333vw;
  .mfooter-img {
    width: 80vw;
    height: 30vw;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    .mfooter-img-content {
      width: 20vw;
      height: 20vw;
      display: flex;
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 100%;
        height: 20vw;
      }
    }
  }
}

.mICP {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
