
<template>
  <div>
    <div v-if="isMobile" class="contact">
      <img
        style="width: 100%; height: 500px"
        class="topimg"
        :src="headView"
        alt=""
      />
      <div class="aboutUs">
        <div class="aboutUs-title">
          <div class="underline"></div>
          <div class="aboutUs-title-name">职业发展</div>
        </div>
        <div class="aboutUs-content">
          {{ content }}
        </div>
        <!-- <div class="aboutUs-content"></div> -->
      </div>
      <div class="remuneration">
        <div class="remuneration-title">
          <div class="underline"></div>
          <div class="remuneration-title-name">薪酬福利</div>
        </div>
        <div class="remuneration-content">
          <div
            class="remuneration-content-1"
            v-for="(item, index) in remuneration"
            :key="index"
          >
            <img
              style="
                margin-top: 40px;
                margin-bottom: 30px;
                width: 85px;
                height: 85px;
              "
              :src="item.img"
              alt=""
            />
            <span style="font-size: 24px; color: #333333">{{
              item.title
            }}</span>
            <div
              style="
                width: 50px;
                height: 2px;
                background: #dc1010;
                margin: 0 auto;
                margin-top: 10px;
              "
            ></div>
            <div
              style="
                font-size: 14px;
                font-weight: 300;
                color: #333333;
                width: 210px;
                line-height: 20px;
                margin-top: 35px;
              "
            >
              {{ item.content }}
            </div>
          </div>
        </div>
        <!-- <div class="aboutUs-content"></div> -->
      </div>
      <div class="remuneration">
        <div class="remuneration-title">
          <div class="underline"></div>
          <div class="remuneration-title-name">招聘岗位</div>
        </div>
        <div class="remuneration-content2">
          <div
            class="remuneration-content2-1"
            v-for="(item, index) in inviteJob"
            :key="index"
          >
            <img
              style="
                margin-top: 20px;
                margin-bottom: 20px;
                width: 123px;
                height: 123px;
              "
              :src="item.img"
              alt=""
            />
            <span style="font-size: 24px; color: #333333">{{
              item.title
            }}</span>
            <div
              style="
                width: 50px;
                height: 2px;
                background: #dc1010;
                margin: 0 auto;
                margin-top: 10px;
              "
            ></div>
            <div
              style="
                font-size: 14px;
                font-weight: 300;
                color: #333333;
                width: 210px;
                line-height: 20px;
                margin-top: 35px;
              "
            >
              {{ item.content }}
            </div>
          </div>
        </div>
        <!-- <div class="aboutUs-content"></div> -->
      </div>
      <div class="joinUs">
        <div class="joinUs-title">
          <div class="underline"></div>
          <div class="joinUs-title-name">联系方式</div>
        </div>
        <div class="joinUs-content">
          <div class="joinUs-content-r">
            <div style="font-size: 27px; font-weight: 400; color: #333333">
              保亿家网络科技有限公司
            </div>
            <div
              style="
                font-size: 18px;
                font-weight: 400;
                color: #333333;
                line-height: 25px;
                padding-bottom: 5px;
                border-bottom: 2px solid #d8d8d8;
              "
            >
              Baoyijia Network Technology Co., Ltd
            </div>
            <div class="message">
              <div style="display: flex">
                <span>地址：</span>
                <span style="width: 300px"
                  >浙江省温州市高新技术产业开发区兴区路55号北航大厦10层</span
                >
              </div>
              <div>
                <span>电话：</span>
                <span>0577-56660201</span>
              </div>
              <div>
                <span>邮箱：</span>
                <span>office@baoyj.net</span>
              </div>
            </div>
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
              "
            >
              <div
                style="
                  width: 113px;
                  height: 113px;
                  margin-top: 10px;
                  margin-left: 10px;
                  box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.15);
                "
              >
                <img
                  style="width: 100%; height: 100%"
                  src="../assets/gzh.jpg"
                  alt=""
                />
              </div>
              <a
                href="https://www.zhipin.com/gongsi/33ca6a84d835de101nx6296_GVY~.html?ka=search_rcmd_company_name_33ca6a84d835de101nx6296_GVY~_custompage"
                class="BOSS"
                >点击前往BOSS直聘</a
              >
            </div>
          </div>
        </div>
        <!-- <div class="aboutUs-content"></div> -->
      </div>
      <!-- <div class="contact-top">
      <div id="allmap" class="allmap" />
      <div class="contactFuc">
        <div class="title">联系方式</div>
        电话：4008600067<br />
        邮箱：admin@baoyj.net<br />
        浙江省温州市高新技术产业开发区兴区路55号<br />
        北航大厦11层<br />
      </div>
    </div> -->
    </div>
    <div v-else>
      <div style="width: 100vw; height: 53.3333vw">
        <img style="width: 100%; height: 100%" :src="headView" alt="" />
      </div>
      <div class="mCompanyProfile">
        <div class="mCompanyProfile-title">公司概况</div>
        <span
          style="
            display: inline-block;
            line-height: 5.8667vw;
            font-size: 3.7333vw;
          "
          >{{ content }}</span
        >
      </div>
      <div class="mcompensationAndWelfare">
        <div class="mcompensationAndWelfare-title">薪酬福利</div>
        <div class="mcompensationAndWelfare-content">
          <div
            class="mcompensationAndWelfare-content-1"
            v-for="(item, index) in remuneration"
            :key="index"
          >
            <img
              style="
                width: 12vw;
                height: 12vw;
                margin-top: 4vw;
                margin-bottom: 2vw;
              "
              :src="item.img"
              alt=""
            />
            <span style="font-size: 5.3333vw; color: #333333">{{
              item.title
            }}</span>
            <div
              style="
                width: 13.3333vw;
                height: 2px;
                background: #dc1010;
                margin: 0 auto;
                margin-top: 5px;
              "
            ></div>
            <div
              style="
                font-size: 3.2vw;
                font-weight: 300;
                color: #333333;
                line-height: 5.3333vw;
                width: 90%;
                margin-top: 2.6667vw;
              "
            >
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
      <div class="mcompensationAndWelfare">
        <div class="mcompensationAndWelfare-title">招聘岗位</div>
        <div class="mcompensationAndWelfare-content">
          <div
            class="mcompensationAndWelfare-content-1"
            v-for="(item, index) in inviteJob"
            :key="index"
          >
            <img
              style="
                width: 18.6667vw;
                height: 18.6667vw;
                margin-top: 5.3333vw;
                margin-bottom: 2.6667vw;
              "
              :src="item.img"
              alt=""
            />
            <span style="font-size: 5.3333vw; color: #333333">{{
              item.title
            }}</span>
            <div
              style="
                width: 13.3333vw;
                height: 2px;
                background: #dc1010;
                margin: 0 auto;
                margin-top: 5px;
              "
            ></div>
            <div
              style="
                font-size: 3.2vw;
                font-weight: 300;
                color: #333333;
                line-height: 5.3333vw;
                width: 90%;
                margin-top: 2.6667vw;
              "
            >
              {{ item.content }}
            </div>
          </div>
        </div>
      </div>
      <div class="contactUs">
        <div class="contactUs-title">联系我们</div>
        <div class="contactUs-content">
          <div style="font-size: 5.3333vw; font-weight: 400; color: #333333">
            保亿家网络科技有限公司
          </div>
          <div
            style="
              font-size: 3.2vw;
              font-weight: 400;
              color: #333333;
              line-height: 6.6667vw;
              padding-bottom: 1.3333vw;
              border-bottom: 2px solid #d8d8d8;
            "
          >
            Baoyijia Network Technology Co., Ltd
          </div>
          <div class="mmessage">
            <div style="display: flex">
              <span>地址：</span>
              <span style="width: 60vw"
                >浙江省温州市高新技术产业开发区兴区路55号北航大厦10层</span
              >
            </div>
            <div>
              <span>电话：</span>
              <span>0577-56660201</span>
            </div>
            <div>
              <span>邮箱：</span>
              <span>office@baoyj.net</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
            "
          >
            <div
              style="
                width: 18vw;
                height: 18vw;
                margin-top: 0.5333vw;
        
                box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.15);
              "
            >
              <img
                style="width: 100%; height: 100%"
                src="../assets/gzh.jpg"
                alt=""
              />
            </div>
            <a
              href="https://www.zhipin.com/gongsi/33ca6a84d835de101nx6296_GVY~.html?ka=search_rcmd_company_name_33ca6a84d835de101nx6296_GVY~_custompage"
              class="BOSS"
              >点击前往BOSS直聘</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BMapLoader } from "@/utils/map";
import { getHeadView, getCareerPlanning } from "../http/api/api";
import serverConfig from "../http/config/index";
export default {
  data() {
    return {
      map: null,
      headView: "",
      content: "",
      form: {
        name: "",
        email: "",
        phone: "",
        address: "",
        type: "合作意向",
        contact: "",
      },
      remuneration: [
        {
          img: require("../assets/remuneration-1.png"),
          title: "薪酬收入",
          content:
            "实行行业具有竞争力的薪酬管理体系，严格执行“提职提薪，降职降薪”原则。",
        },
        {
          img: require("../assets/remuneration-2.png"),
          title: "社会保险",
          content:
            "按国家、省、市有关规定交纳养老、工伤、生育、失业、医疗等社会保险。",
        },
        {
          img: require("../assets/remuneration-3.png"),
          title: "培训发展",
          content:
            "根据岗位需要开展新员工入职培训及部门专业培训等多种形式的培训。",
        },
        {
          img: require("../assets/remuneration-4.png"),
          title: "其他福利",
          content:
            "五险一金、企业年金、节日福利、法定节假日、带薪年假、员工食堂，定期体检。",
        },
      ],
      inviteJob: [
        {
          img: require("../assets/remuneration-5.png"),
          title: "技术类",
          content: "java开发工程师、前端开发工程师、UI设计师。",
        },

        {
          img: require("../assets/remuneration-8.png"),
          title: "财务部",
          content: "会计、出纳。",
        },
      ],
      isMobile: false,
    };
  },
  created() {
    this.initMap();
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  mounted() {
    this.initMap();
    this.getHeadView();
    this.getCareerPlanning();
  },
  methods: {
    getCareerPlanning() {
      getCareerPlanning().then((res) => {
        this.content = res.data[0].content;
      });
    },
    getHeadView() {
      getHeadView(15).then((res) => {
        res.data.forEach((item) => {
          this.headView = serverConfig.baseURL + item.imgUrl;
        });
      });
    },
    initMap() {
      this.$nextTick(() => {
        BMapLoader("oxlvdUxV5KHd4wDyGOGbsKGZVgQn4iTz").then((BMap) => {
          const map = new BMap.Map("allmap", { enableMapClick: false });
          this.map = map;
          map.disableKeyboard(); // 禁用键盘操作地图
          map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
          // 添加中心点和缩放等级，如果不添加，初始化出来页面为白屏
          const point = new BMap.Point(120.736916, 27.979056);
          map.centerAndZoom(point, 15);
        });
      });
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  display: flex;
  flex-direction: column;
  .aboutUs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
    margin-top: 50px;
    .aboutUs-title {
      .underline {
        width: 180px;
        height: 4px;
        background: #dc1010;
        // margin-top: 6px;
        // margin-bottom: 25px;
      }
      .aboutUs-title-name {
        font-size: 36px;
        font-weight: 500;
        color: #dc1010;
        margin-top: 20px;
      }
    }
    .aboutUs-content {
      width: 1000px;
      padding-right: 20px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      // .underline {
      //   width: 1000px;
      //   height: 1px;
      //   background: #999999;
      // }
    }
  }
  .remuneration {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
    margin-top: 50px;
    .remuneration-title {
      .underline {
        width: 180px;
        height: 4px;
        background: #dc1010;
        // margin-top: 6px;
        // margin-bottom: 25px;
      }
      .remuneration-title-name {
        font-size: 36px;
        font-weight: 500;
        color: #dc1010;
        margin-top: 20px;
      }
    }
    .remuneration-content {
      width: 1000px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      .remuneration-content-1 {
        width: 240px;
        height: 350px;
        // background: url(../assets/remuneration.png);
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        box-shadow: -1px 1px 10px 1px #efefef;
        border-radius: 0px 20px 0px 20px;
        transition: all 0.5s;
      }
      .remuneration-content-1:hover {
        transform: scale(1.03);
      }
    }

    .remuneration-content2 {
      width: 1000px;
      box-sizing: border-box;
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 30px;
      display: flex;

      .remuneration-content2-1 {
        width: 240px;
        height: 350px;
        // background: url(../assets/remuneration.png);
        display: flex;
        margin-right: 15px;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        box-shadow: -1px 1px 10px 1px #efefef;
        border-radius: 0px 20px 0px 20px;
        transition: all 0.5s;
      }
      .remuneration-content2-1:hover {
        transform: scale(1.03);
      }
    }
  }
  .joinUs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
    margin-top: 50px;
    .joinUs-title {
      .underline {
        width: 180px;
        height: 4px;
        background: #dc1010;
        // margin-top: 6px;
        // margin-bottom: 25px;
      }
      .joinUs-title-name {
        font-size: 36px;
        font-weight: 500;
        color: #dc1010;
        margin-top: 20px;
      }
    }
    .joinUs-content {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      .joinUs-content-r {
        width: 465px;
        height: 362px;
        box-shadow: -1px 1px 10px 1px #efefef;
        padding: 20px 0px 0px 20px;
        box-sizing: border-box;
        .message {
          display: flex;
          flex-direction: column;
          font-size: 18px;
          font-weight: 400;
          color: #2d2d2d;
          margin-top: 15px;
          line-height: 30px;
        }
        .BOSS {
          font-size: 20px;
          font-weight: 500;
          color: #999999;
          text-decoration: none;
          margin-right: 20px;
        }
      }
    }
  }
}
.contact-top {
  width: 100%;
  display: flex;
}
.allmap {
  width: 516px;
  height: 362px;
  box-shadow: -1px 1px 10px 1px #efefef;
}

.contactFuc {
  height: 600px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  font-size: 14px;
  .title {
    font-size: 18px;
    font-weight: 600;
  }
}

.mCompanyProfile {
  width: 85vw;
  margin: 0 auto;
  margin-top: 5.3333vw;
  .mCompanyProfile-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
}

.mcompensationAndWelfare {
  width: 85vw;
  margin: 0 auto;
  margin-top: 5.3333vw;

  .mcompensationAndWelfare-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
  .mcompensationAndWelfare-content {
    width: 85vw;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    .mcompensationAndWelfare-content-1 {
      width: 41vw;
      height: 53.3333vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      box-shadow: -1px 1px 10px 1px #efefef;
      border-radius: 0px 5.3333vw 0px 5.3333vw;
      &.mcompensationAndWelfare-content-1:nth-child(n + 3) {
        margin-top: 2.6667vw;
      }
    }
  }
}
.contactUs {
  width: 85vw;
  margin: 0 auto;
  margin-top: 5.3333vw;
  .contactUs-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }

  .contactUs-content {
    width: 85vw;
    height: 66.6667vw;
    box-shadow: -1px 1px 10px 1px #efefef;
    padding: 3vw 0px 0px 5.3333vw;
    padding-top: 1.3333vw;
    box-sizing: border-box;
    .mmessage {
      display: flex;
      flex-direction: column;
      font-size: 3.7333vw;
      font-weight: 400;
      color: #2d2d2d;
      margin-top: 2.6667vw;
      line-height: 6.6667vw;
    }
    .BOSS {
      font-size: 4.2667vw;
      font-weight: 500;
      color: #999999;
      text-decoration: none;
      margin-right: 6vw;
    }
  }
}
</style>