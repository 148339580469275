
<template>
  <div>
    <div v-if="isMobile" class="contact">
      <img
        style="width: 100%; height: 500px"
        class="topimg"
        :src="headView"
        alt=""
      />
      <div class="joinUs">
        <div class="joinUs-title">
          <div class="underline"></div>
          <div class="joinUs-title-name">公司地址</div>
        </div>
        <div style="width: 1000px">
          <div class="joinUs-content">
            <div id="allmap" class="allmap" />
            <div class="joinUs-content-r">
              <div style="font-size: 27px; font-weight: 400; color: #333333">
                保亿家网络科技有限公司
              </div>
              <div
                style="
                  font-size: 18px;
                  font-weight: 400;
                  color: #333333;
                  line-height: 25px;
                  padding-bottom: 5px;
                  border-bottom: 2px solid #d8d8d8;
                "
              >
                Baoyijia Network Technology Co., Ltd
              </div>
              <div class="message">
                <div style="display: flex">
                  <span>地址：</span>
                  <span style="width: 300px"
                    >浙江省温州市高新技术产业开发区兴区路55号北航大厦10层</span
                  >
                </div>
                <div>
                  <span>电话：</span>
                  <span>0577-56660201</span>
                </div>
                <div>
                  <span>邮箱：</span>
                  <span>office@baoyj.net</span>
                </div>
              </div>
              <div
                style="
                  display: flex;
                  justify-content: space-between;
                  align-items: flex-end;
                "
              >
                <div
                  style="
                    width: 113px;
                    height: 113px;
                    margin-top: 10px;
                    margin-left: 10px;
                    box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.15);
                  "
                >
                  <img
                    style="width: 100%; height: 100%"
                    src="../assets/gzh.jpg"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="subsidiaryCompany">
            <div
              class="subsidiaryCompany-1"
              v-for="(item, index) of subsidiaryCompany"
              :key="index"
            >
              <div class="subsidiaryCompany-name">{{ item.name }}</div>
              <div
                style="
                  display: flex;
                  flex-direction: column;
                  justify-content: space-evenly;
                  height: 130px;
                  margin-left: 14px;
                "
              >
                <div class="subsidiaryCompany-address">
                  <span>地址：{{ item.local }}</span>
                </div>
                <div class="subsidiaryCompany-telephone">
                  <span v-if="item.tel">电话：{{ item.tel }}</span>
                </div>
                <div class="subsidiaryCompany-mailbox">
                  <span v-if="item.email">邮箱：{{ item.email }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="aboutUs-content"></div> -->
      </div>
      <div class="interactiveMessage">
        <div class="interactiveMessage-title">
          <div class="underline"></div>
          <div class="interactiveMessage-title-name">互动留言</div>
        </div>
        <div class="interactiveMessage-content">
          <el-form :model="form" size="medium" label-position="right">
            <el-row>
              <el-col span="8">
                <el-form-item label="我是">
                  <el-input
                    style="width: 272px; margin-right: 20px; margin-left: 28px"
                    v-model="form.name"
                  />
                </el-form-item>
              </el-col>
              <el-col span="16">
                <el-form-item label="我来自">
                  <el-input
                    style="width: 500px; margin-left: 13px"
                    v-model="form.local"
                  />
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col span="8">
                <el-form-item label="我的职业">
                  <el-input
                    style="width: 272px; margin-right: 20px"
                    v-model="form.career"
                  />
                </el-form-item>
              </el-col>
              <el-col span="16">
                <el-form-item label="电子邮箱">
                  <el-input style="width: 500px" v-model="form.email" />
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="留意内容">
              <el-input
                rows="5"
                style="width: 860px"
                v-model="form.values"
                type="textarea"
              />
            </el-form-item>
            <el-form-item style="margin-left: 68px">
              <el-button type="danger" @click="onSubmit">提交</el-button>
              <el-button @click="clear">清空</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
    <div v-else>
      <div style="width: 100vw; height: 53.3333vw">
        <img style="width: 100%; height: 100%" :src="headView" alt="" />
      </div>

      <div class="mCompanyAddress">
        <div class="mCompanyAddress-title">公司地址</div>
        <div
          id="allmap"
          style="
            width: 85vw;
            height: 53.3333vw;
            margin: 0 auto;
            margin-bottom: 2.6667vw;
          "
        />
        <div class="contactUs">
          <div class="contactUs-content">
          <div style="font-size: 5.3333vw; font-weight: 400; color: #333333">
            保亿家网络科技有限公司
          </div>
          <div
            style="
              font-size: 3.2vw;
              font-weight: 400;
              color: #333333;
              line-height: 6.6667vw;
              padding-bottom: 1.3333vw;
              border-bottom: 2px solid #d8d8d8;
            "
          >
            Baoyijia Network Technology Co., Ltd
          </div>
          <div class="mmessage">
            <div style="display: flex">
              <span>地址：</span>
              <span style="width: 60vw"
                >浙江省温州市高新技术产业开发区兴区路55号北航大厦10层</span
              >
            </div>
            <div>
              <span>电话：</span>
              <span>0577-56660201</span>
            </div>
            <div>
              <span>邮箱：</span>
              <span>office@baoyj.net</span>
            </div>
          </div>
          <div
            style="
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
            "
          >
            <div
              style="
                width: 18vw;
                height: 18vw;
                margin-top: 0.5333vw;
        
                box-shadow: 2px 2px 5px 0px rgba(0, 0, 0, 0.15);
              "
            >
              <img
                style="width: 100%; height: 100%"
                src="../assets/gzh.jpg"
                alt=""
              />
            </div>
          
          </div>
        </div>
        </div>
        <div class="mSubsidiaryCompany">
          <div
            class="mSubsidiaryCompany-content"
            v-for="(item, index) of subsidiaryCompany"
            :key="index"
          >
            <div class="mSubsidiaryCompany-content-title">
              {{ item.name }}
            </div>
            <div class="mSubsidiaryCompany-content-address">
              <span>地址：{{ item.local }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="minteractiveMessage">
        <div class="minteractiveMessage-title">互动留言</div>
        <div style="width: 98vw; margin: 0 auto">
          <van-cell-group inset>
            <van-field v-model="form.name" label="我是" />
            <van-field v-model="form.local" label="我来自" />
            <van-field v-model="form.career" label="我的职业" />
            <van-field v-model="form.email" label="电子邮箱" />
            <van-field
              v-model="form.values"
              rows="2"
              autosize
              label="留意内容"
              type="textarea"
            />
          </van-cell-group>
          <van-button style="height: 9.3333vw;margin-left: 7vw;" type="danger" @click="onSubmit">提交</van-button>
          <van-button style="height: 9.3333vw;margin-left: 2.6667vw;"  @click="clear" type="default">清空</van-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { BMapLoader } from "@/utils/map";
import {
  getHeadView,
  getSubsidiaryCompany,
  interactiveMessage,
} from "../http/api/api";
import serverConfig from "../http/config/index";
export default {
  data() {
    return {
      map: null,
      form: {
        name: "",
        local: "",
        career: "",
        email: "",
        values: "",
      },
      subsidiaryCompany: [],
      headView: null,
      isMobile: false,
    };
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);

    this.getSubsidiaryCompany();
    this.getHeadView();
  },
  mounted() {
    setTimeout(() => {
      this.initMap();
    }, 1000);
  },
  methods: {
    initMap() {
      this.$nextTick(() => {
        BMapLoader("oxlvdUxV5KHd4wDyGOGbsKGZVgQn4iTz").then((BMap) => {
          const map = new BMap.Map("allmap", { enableMapClick: false });
          this.map = map;
          map.disableKeyboard(); // 禁用键盘操作地图
          map.enableScrollWheelZoom(true); // 开启鼠标滚轮缩放
          // 添加中心点和缩放等级，如果不添加，初始化出来页面为白屏
          const point = new BMap.Point(120.736916, 27.979056);
          map.centerAndZoom(point, 15);
        });
      });
    },
    getSubsidiaryCompany() {
      getSubsidiaryCompany().then((res) => {
        this.subsidiaryCompany = res.data;
      });
    },
    getHeadView() {
      getHeadView(16).then((res) => {
        res.data.forEach((item) => {
          this.headView = serverConfig.baseURL + item.imgUrl;
        });
        console.log(this.headView);
      });
    },
    onSubmit() {
      let data = this.form;
      interactiveMessage(data).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: "留言成功",
            type: "success",
          });
          this.form = {};
        } else if (res.code == 500) {
          this.$message.error(res.msg);
        }
      });
    },
    clear() {
      this.form = {};
    },
    checkDeviceType() {
      const userAgent = navigator.userAgent.toLowerCase();
      const minWidthForMobile = 768;
      if (
        userAgent.match(
          /(android|iphone|ipad|ipod|webos|blackberry|iemobile|opera mini)/i
        )
      ) {
        this.isMobile = false;
      } else if (window.innerWidth < minWidthForMobile) {
        this.isMobile = false;
      } else {
        this.isMobile = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  display: flex;
  flex-direction: column;

  .joinUs {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 75px;
    margin-top: 70px;
    .joinUs-title {
      .underline {
        width: 180px;
        height: 4px;
        background: #dc1010;
        // margin-top: 6px;
        // margin-bottom: 25px;
      }
      .joinUs-title-name {
        font-size: 36px;
        font-weight: 500;
        color: #dc1010;
        margin-top: 20px;
      }
    }
    .joinUs-content {
      width: 1000px;
      display: flex;
      justify-content: space-between;
      .joinUs-content-r {
        width: 465px;
        height: 362px;
        box-shadow: -1px 1px 10px 1px #efefef;
        padding: 20px 0px 0px 20px;
        box-sizing: border-box;
        .message {
          display: flex;
          flex-direction: column;
          font-size: 18px;
          font-weight: 400;
          color: #2d2d2d;
          margin-top: 15px;
          line-height: 30px;
        }
      }
    }
    .subsidiaryCompany {
      width: 100%;
      // height: 200px;
      margin-top: 20px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .subsidiaryCompany-1 {
        width: 318px;
        height: 184px;
        background: #ffffff;
        box-shadow: -1px 1px 10px 1px #efefef;
        padding-top: 10px;
        padding-right: 1px;
        box-sizing: border-box;
        margin-top: 20px;
        transition: all 0.5s;

        .subsidiaryCompany-name {
          width: 293px;
          height: 41px;
          background: #ebebeb;
          text-align: center;
          margin: 0 auto;
          font-size: 20px;
          line-height: 41px;

          box-sizing: border-box;
        }
        .subsidiaryCompany-address {
          font-size: 16px;
          font-weight: 400;
          color: #2d2d2d;
        }
        .subsidiaryCompany-telephone {
          font-size: 16px;
          font-weight: 400;
          color: #2d2d2d;
        }
        .subsidiaryCompany-mailbox {
          font-size: 16px;
          font-weight: 400;
          color: #2d2d2d;
        }
      }
      .subsidiaryCompany-1:hover {
        transform: scale(1.05);
      }
    }
  }
  .interactiveMessage {
    display: flex;
    .interactiveMessage-title {
      .underline {
        width: 180px;
        height: 4px;
        background: #dc1010;
        // margin-top: 6px;
        // margin-bottom: 25px;
      }
      .interactiveMessage-title-name {
        font-size: 36px;
        font-weight: 500;
        color: #dc1010;
        margin-top: 20px;
      }
    }
    .interactiveMessage-content {
      width: 1000px;
      padding-left: 20px;
      box-sizing: border-box;
    }
  }
}
.contact-top {
  width: 100%;
  display: flex;
}
.allmap {
  width: 516px;
  height: 362px;
  box-shadow: -1px 1px 10px 1px #efefef;
}

.contactFuc {
  height: 600px;
  line-height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 50px;
  font-size: 14px;
  .title {
    font-size: 18px;
    font-weight: 600;
  }
}

.mCompanyAddress {
  width: 85vw;
  margin: 0 auto;
  margin-top: 5.3333vw;
  .mCompanyAddress-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
}

.mSubsidiaryCompany {
  width: 85vw;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  .mSubsidiaryCompany-content {
    width: 41vw;
    height: 34.6667vw;
    background: #ffffff;
    box-shadow: -1px 1px 10px 1px #efefef;
    box-sizing: border-box;
    margin-top: 2.6667vw;
    .mSubsidiaryCompany-content-title {
      width: 35vw;
      height: 8vw;
      background: #ebebeb;
      text-align: center;
      margin: 0 auto;
      font-size: 4.2667vw;
      line-height: 8vw;
      box-sizing: border-box;
      margin-top: 2.6667vw;
      margin-bottom: 2.6667vw;
    }

    .mSubsidiaryCompany-content-address {
      width: 37vw;
      font-size: 3.2vw;
      font-weight: 400;
      color: #2d2d2d;
      margin: 0 auto;
    }
  }
}

.contactUs {
  width: 85vw;
  margin: 0 auto;

  .contactUs-content {
    width: 85vw;
    height: 66.6667vw;
    box-shadow: -1px 1px 10px 1px #efefef;
    padding: 3vw 0px 0px 5.3333vw;
    padding-top: 1.3333vw;
    box-sizing: border-box;
    .mmessage {
      display: flex;
      flex-direction: column;
      font-size: 3.7333vw;
      font-weight: 400;
      color: #2d2d2d;
      margin-top: 2.6667vw;
      line-height: 6.6667vw;
    }
    .BOSS {
      font-size: 4.2667vw;
      font-weight: 500;
      color: #999999;
      text-decoration: none;
      margin-right: 6vw;
    }
  }
}

.minteractiveMessage {
  margin-top: 5.3333vw;
  .minteractiveMessage-title {
    display: flex;
    justify-content: center;
    font-size: 5.5vw;
    font-weight: 500;
    color: #dc1010;
    padding-bottom: 1.3333vw;
    margin-bottom: 1.3333vw;
  }
}
</style>